<template>
  <div class="profile-container pb-5">
    <div class="profile-title">
      <h4 class="profile-name">My Profile</h4>
      <!--div>
        <router-link id="btn" class="btn btn" to="/login">Edit</router-link>
      </div-->
    </div>

    <div class="form-container p-4">
      
      <div class="row">
        <h5 class="text-accent col-12">Account Information</h5>
        <div class="col-12 row">
          <div class="col-12 col-md-4">
            <div class="img-containe p-3">
              <div class="rounded-3"><img :src="profile" alt="" width="150" height="150"/></div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="col-12">
              <label class="mb-1">First Name</label>
              <div class="mb-3">
                <input
                  v-model="firstname"
                  type="text"
                  class="form-control input form-control-lg"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                />
              </div>
            </div>

            <div class="col-12">
              <label class="mb-1">Last Name</label>
              <div class="mb-3">
                <input
                  v-model="lastname"
                  type="text"
                  class="form-control lastName form-control-lg"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- contact Details -->
        <h5 class="text-accent col-12">Contact Details</h5>
        <div class="col-12 col-md-6">
          <label class="mb-1">Phone </label>
          <div class="mb-3">
            <input
              v-model="phone"
              type="text"
              class="form-control phone form-control-lg"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
            />
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label class="mb-1">Email</label>
          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control input form-control-lg"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              readonly
            />
          </div>
        </div>
        <div class="col-12">
          <label class="mb-1">Contact Address</label>
          <div class="mb-3">
            <input
              v-model="address"
              type="text"
              class="form-control form-control-lg"
              aria-label="Sizing example address"
              aria-describedby="inputGroup-sizing-default"
            />
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label class="mb-1">State&nbsp;&nbsp;</label>
          <!-- <span id="errorMsg" v-if="v$.state_id.$error">
            {{ v$.state_id.$errors[0].$message }}
          </span> -->
          <select
            id="select"
            class="form-select form-control-lg"
            aria-label="Default select example"
            @change="getCity($event)"
            v-model="state_id"
          >
            <option
              :key="index"
              :value="state.id"
              v-for="(state, index) in States"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>City&nbsp;&nbsp;</label>
          <!-- <span id="errorMsg" v-if="v$.city_id.$error">
            {{ v$.city_id.$errors[0].$message }}
          </span> -->
          <select
            id="select"
            class="form-select form-control-lg"
            aria-label="Default select example"
            v-model="city_id"
          >
            <option
              v-for="(city, index) in Cities"
              :key="index"
              :value="city.id"
            >
              {{ city.name }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <Button id="input-submit" class="btn-block" @click="updateProfile()" buttonText="Save" />
        </div>
        <!--Update password -->
        <h5 class="text-accent col-12">Change Password</h5>
        <div class="col-12">
          <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
          />
          <label class="mb-1">Old Password </label>
          <div class="mb-3">
            <input
              type="password"
              v-model="state.oldPassword"
              class="form-control form-control-lg"
              placeholder="**********"
            />
          </div>
          <span id="error_msg" v-if="v$.oldPassword.$error">
            {{ v$.oldPassword.$errors[0].$message }}
          </span>
        </div>

        <div class="col-12">
          <label class="mb-1">New Password</label>
          <div class="mb-3">
            <input
              type="password"
              v-model="state.password"
              class="form-control form-control-lg"
              placeholder="**********"
            />
          </div>
          <span id="error_msg" v-if="v$.password.$error">
            {{ v$.password.$errors[0].$message }}
          </span>
        </div>

        <div class="col-12">
          <label class="mb-1">Confirm New Password</label>
          <div class="mb-3">
            <input
              type="password"
              class="form-control input form-control-lg"
              v-model="state.password_confirmation"
              placeholder="**********"
            />
          </div>
          <span id="error_msg" v-if="v$.password_confirmation.$error">
            {{ v$.password_confirmation.$errors[0].$message }}
          </span>
        </div>
        <div class="col-12">
          <Button
            id="input-submit"
            @click="updatePassword()"
            buttonText="Save Changes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/ReusableUI/button.vue";
import { reactive, computed } from "vue";
import { required, minLength, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "Myprofile",
  data() {
    return {
      profile: require("../assets/soup.png"),
      isLoading: false,
      fullPage: true,
      info: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      city_id: "",
      state_id: "",
      States: [],
    };
  },

  setup() {
    const state = reactive({
      oldPassword: "",
      password: "",
      password_confirmation: "",
    });

    const rules = computed(() => {
      return {
        oldPassword: { required },
        password: { required, minLength: minLength(6) },
        password_confirmation: { required, sameAs: sameAs(state.password) },
      };
    });

    const v$ = useVuelidate(rules, state);
    return { state, v$ };
  },

  validations() {
    return {
      oldPassword: { required },
      password: { required },
      password_confirmation: { required },
    };
  },

  components: {
    Button,
    Loading,
  },

  methods: {
    ...mapActions({
      passcodeUpdate: "general/updatePasscode",
      updateUserProfile: "general/updateProfile",
    }),

    updatePassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isLoading = true;
        const { oldPassword, password } = this.state;
        const data = {
          current_password: oldPassword,
          new_password: password,
        };
        this.passcodeUpdate(data).then((data) => {
          if (data == undefined) {
            this.isLoading = false;
            this.state = "";
          } else {
            this.isLoading = false;
          }
        });
      } else {
        return;
      }
    },

    updateProfile() {
      let data = {
        first_name: this.firstname,
        last_name: this.lastname,
        phone: this.phone,
        state_id: this.state_id,
        city_id: this.city_id,
        address: this.address,
      };
      if (data.address == "") {
        return;
      } else {
        this.isLoading = true;
        this.updateUserProfile(data).then((data) => {
          if (data == undefined) {
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
      }
    },

    getCity(e) {
      this.stateID = e.target.value;
      this.Cities = this.States.find((city) => city.id === this.stateID).cities;
    },
  },

  async created() {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    this.info = userInfo;
    this.firstname = userInfo.first_name;
    this.lastname = userInfo.last_name;
    this.email = userInfo.email;
    this.phone = userInfo.phone;
    this.States = this.$store.state["general"].states;
  },
};
</script>

<style scoped>
.profile-container h5 {
  margin: 5% 0%;
  color: #04030f;
  font-weight: 500;
}

.profile-title {
  display: flex;
  justify-content: space-between;
}

#error_msg {
  color: red;
}

.profile-name {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.img-container {
  text-align: center;
  justify-content: center;

}

.input-group input {
  width: 70%;
  margin-right: 15%;
}

.form-control {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#input2 {
  width: 70%;
  margin-right: 15%;
}

#input3 {
  width: 70%;
  margin-right: 15%;
}

#input-submit {
  /*width: 86%;*/
  margin-top: 25px;
}

#input1 {
  width: 53%;
  margin-right: 30%;
}

#city {
  width: 53%;
  margin-right: 30%;
}

#area {
  width: 53%;
  margin-right: 30%;
}

.text-accent {
  color: rgba(133, 77, 39, 1);
  font-weight: 500;
}

@media only screen and (max-width: 700px) {
  #input1 {
    width: 100%;
    margin-right: 13%;
  }
}
</style>
