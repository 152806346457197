<template>
  <div id="home">
    <router-link class="back" to="login">Go back Home</router-link>
  </div>
  <div class="card-container">
    <div class="card" style="width: 18rem">
      <div class="card-body">
        <h5>Verify for Email</h5>
        <div class="verify-content">
          <img :src="verify" alt="verify" />
        </div>

        <p>
          A verification link has been sent to the email address provided.
          Please, click on the link to verify your email
        </p>

        <div class="row" id="resend-link">
          <div class="col-md-6">Didn’t receive verification?</div>
          <div class="col-md-6">
            <div id="btn" class="btn btn" @click="resendMail()">
              Resend Verification Link
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      verify: require("../../../assets/verify.png"),
    };
  },

  methods: {
    ...mapActions({
      ResendMail: "auth/sendVerificationMail",
    }),

    resendMail() {
      this.ResendMail();
    },
  },
};
</script>

<style>
#home {
  float: right;
  padding: 0% 8%;
  padding-top: 3%;
}
.card {
  box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.06);
  width: 100% !important;
  border: none !important;
}
.card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.card-container h5 {
  color: #04030f;
  padding-bottom: 5%;
}

.verify-content img {
  width: 30%;
  padding-bottom: 5%;
}

.card-body p {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #04030f;
  padding: 0px 15%;
  padding-bottom: 5%;
}

#btn {
  color: #854d27;
  padding: 3px 20px;
  border: 0.5px solid #854d27;
  outline-color: #854d27 !important;
}

.back {
  color: rgba(46, 48, 45, 1);
  text-decoration: none;
}

.back:hover {
  color: rgba(46, 48, 45, 1);
}

#resend-link {
  padding-bottom: 5%;
}
</style>
