import axios from "axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,

  state: {
    riderInfo: null,
  },

  mutations: {},

  getters: {},

  actions: {
    async createRider(_, credentials) {
      return axios.post(`rider/store`, credentials).then((response) => {
        if (response.data.code == 201) {
          Swal.fire("Sucessful", response.data.message, "success");
        } else {
          Swal.fire(
            {
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              });
            }
          );
        }
      });
    },
  },
};
