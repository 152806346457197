import axios from "axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,

  state: {
    order: null,
    availableUserOrders: null,
  },

  mutations: {
    // USER_INFO(state, vendor) {
    //   state.vendor = vendor;
    // },

    USER_ORDER(state, availableUserOrders) {
      state.availableUserOrders = availableUserOrders;
    },
  },

  getters: {
    availableUserVendors(state) {
      return state.vendor;
    },
  },

  //  place order
  actions: {
    async placeOrder(_, credentials) {
      return axios.post(`order`, credentials).then((response) => {
        if (response.data.code == 201) {
          Swal.fire("Sucessful", response.data.message, "success");
        } else {
          Swal.fire(
            {
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              });
            }
          );
        }
      });
    },

    async userOrder({ dispatch }) {
      return axios.get(`/my-orders`).then((response) => {
        let userOrderArray = [];
        const orders = response.data.data;
        for (let i = 0; i < orders.length; i++) {
          orders[i].menu_order = orders[i].menu_order.map((m) => {
            return {
              ...m,
              business_address: orders[i].vendor.business_address,
              business_name: orders[i].vendor.business_name,
              formartedDate: orders[i].created_at.split("T")[0],
              formartedTime: orders[i].created_at.split("T")[1].slice(0, 5),
              time_acknowledged: orders[i].time_acknowledged,
              time_delivered: orders[i].time_delivered,
              time_dispatched: orders[i].time_dispatched,
              time_processing: orders[i].time_processing,
            };
          });
        }
        for (let i = 0; i < orders.length; i++) {
          userOrderArray = [...userOrderArray, ...orders[i].menu_order];
        }
        dispatch("userOrders", userOrderArray);
      });
    },

    // update order
    async updateOrder(_, data) {
      return axios.put(`vendor/order`, data).then((response) => {
        this.isLoading = true;
        if (response.data.code == 200) {
          Swal.fire("Success", response.data.message, "success");
          this.isLoading = false;
        } else {
          this.isLoading = false;
          Swal.fire(
            {
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            },
            (err) => {
              this.isLoading = false;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              });
            }
          );
        }
      });
    },

    // commit user orders
    async userOrders({ commit }, orders) {
      commit("USER_ORDER", orders);
    },
  },
};
