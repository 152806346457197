<template>
  <div class="single-food">
    <!-- <h4>Sushi</h4> -->
    <div class="whole-container">
      <div class="product-details" v-for="(product, index) of availableProducts" :key="index">
        <div class="product-image">
          <img :src="product.image
          " alt="menu_image" class="rounded" />
        </div>
        <div class="p-name">
          <div class="f-container">
            <div class="food-name">{{ product.name }}</div>
            <div class="food-descr">{{ product.description }}</div>
            <div class="f-price">&#8358;{{ product.price }}</div>
          </div>
          <div @click="addToCart(product)" class="add">
            <img :src="add" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- Order check Modal -->
    <div v-if="modal">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <div class="header-title">
                    <div>Order Status</div>
                    <div class="close" @click="modal = false" aria-hidden="true">
                      &times;
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <p>
                    Your previous order will be cleared if you proceed with this
                    vendor.
                  </p>
                  <div class="action-btn">
                    <div class="button1">
                      <button @click="clearCart()">Proceed</button>
                    </div>
                    <div class="button2">
                      <button @click="goToCart()">Check Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "product",
  props: ["availableProducts"],
  data() {
    return {
      product: require("../../../assets/product1.png"),
      product2: require("../../../assets/product2.png"),
      product3: require("../../../assets/product3.png"),
      add: require("../../../assets/cross.png"),
      products: [],
      modal: false,
      cartItem: [],
      checkId: null,
    };
  },

  methods: {
    ...mapActions({
      addItem: "general/addToProductCart",
    }),

    addToCart(product) {
      let productId = product.vendor_list_id;
      if(this.checkId == null || this.checkId == productId) {
        this.addItem({
          product: product,
          quantity: 1,
        });
      } else {
        this.check();
      }
    },

    check() {
      this.modal = true;
    },

    clearCart() {
      localStorage.setItem("cart", 0);
      this.$store.state["general"].cartItems = [];
      this.checkId = null;
      this.modal = false;
      Swal.fire("Cart cleared", "Add new item to cart", "success");
    },

    goToCart() {
      router.push({ name: "checkout" });
    },
  },

  async created() {
    this.cartItem = this.$store.state["general"].cartItems;
    for(var i = 0; i < this.cartItem.length; i++) {
      this.checkId = this.cartItem[i].product.vendor_list_id;
    }
  },
};
</script>

<style scopped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.5s ease-in-out;
}

.modal-wapper {
  display: table-cell;
  vertical-align: middle;
}

.action-btn {
  display: flex;
}

.button1 button {
  border: none;
  border: 2px solid #854d27;
  background-color: #fff;
  color: black;
  border-radius: 50px;
  margin-right: 20px;
  padding: 5px 15px;
}

.button1 button:hover {
  background-color: #854d27;
  color: white;
}

.button2 button:hover {
  background-color: #854d27;
  color: white;
}

.button2 button {
  border: none;
  background-color: #fff;
  border: 2px solid #854d27;
  color: black;
  border-radius: 50px;
  margin-right: 20px;
  padding: 5px 15px;
}

.header-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.close {
  cursor: pointer;
}

.product-details {
  display: flex;
  /* padding-bottom: 3%; */
}

.food-name {
  color: rgba(4, 3, 15, 1);
}

.p-name {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product-image img {
  width: 70%;
  height: auto;
}

.f-container {
  padding-left: 20px;
}

.add img {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.food-descr {
  font-size: 14px;
  color: #43424c;
  font-weight: 600;
}

.f-price {
  color: #854d27;
  font-size: 14px;
  font-weight: 600;
}

.whole-container {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 3%;
}
</style>
