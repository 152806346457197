<template>
  <div class="category-container">
    <h4>Other Info</h4>
    <p>What do you have ?</p>

    <div class="row">
      <div class="col-md">
        <ul>
          <li id="food" v-for="(item, index) in requirements" :key="index">
            <div class="form-check">
              <input
                class="form-check-input"
                @change="checkValue($event, item)"
                type="checkbox"
                value="item.name"
                :id="flexCheckDefault + item.name"
              />
            </div>
            <label :for="flexCheckDefault + item.name">{{ item.name }}</label>
          </li>
        </ul>
      </div>

      <div class="city-area">
        <label>Your start Date</label>
        <div class="input-group input-group mb-3">
          <input
            type="date"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            v-model="value.start_date"
          />
        </div>
      </div>

      <div class="city-area">
        <label>Which Areas in the city do you know best?</label>
        <div class="input-group input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="City 1"
            v-model="value.known_areas1"
          />
        </div>

        <div class="input-group input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="City 2"
            v-model="value.known_areas2"
          />
        </div>

        <div class="input-group input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="City 3"
            v-model="value.known_areas3"
          />
        </div>
      </div>

      <!-- buttons -->
      <div class="controllers">
        <div class="prev-button"><button @click="back">PREVIOUS</button></div>
        <div><Button id="btn" @click="Submit" btnText="CONTINUE" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/ReusableUI/SmalllButton.vue";
import router from "@/router";
import { reactive, computed } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// import { mapActions, mapGetters } from "vuex";

export default {
  name: "FoodCategory",
  props: ["info"],

  setup() {
    const value = reactive({
      start_date: "",
      known_areas1: [],
      known_areas2: [],
      known_areas3: [],
    });

    const rules = computed(() => {
      return {
        start_date: { required },
        known_areas1: { required },
        known_areas2: { required },
        known_areas3: { required },
      };
    });

    const v$ = useVuelidate(rules, value);
    return { value, v$ };
  },

  data() {
    return {
      requirements: [
        { id: 1, name: "smartPhone" },
        { id: 2, name: "Helmet" },
        { id: 3, name: "Personal insurance" },
        { id: 4, name: "motor bike" },
        { id: 5, name: "Bike insurance" },
      ],
      regInfo: "",
      selectedProperties: [],
      selectedKnownAreas: [],
      basicRegInfo: {},
    };
  },

  components: {
    Button,
  },

  validations() {
    return {
      start_date: { required },
      known_areas1: { required },
      known_areas2: { required },
      known_areas3: { required },
    };
  },

  methods: {
    back() {
      router.push({ name: "vendor-page" });
    },

    Submit(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        const { start_date, known_areas1, known_areas2, known_areas3 } =
          this.value;
        const areaData = {
          known_areas1,
          known_areas2,
          known_areas3,
        };
        this.selectedKnownAreas.push(areaData);
        const otherInfo = {
          known_areas: this.selectedKnownAreas,
          properties: this.selectedProperties,
          start_date,
        };
        const data = { ...this.basicRegInfo, ...otherInfo };
        this.$store.state["rider"].riderInfo = data;
        router.push({ name: "upload" });
      }
    },

    // method to get properties
    checkValue(event, item) {
      if (event.target.checked == true) {
        this.selectedProperties.push(item.name);
      } else {
        this.selectedProperties.map((i) => {
          if (i == item.name) {
            this.selectedProperties.splice(
              this.selectedProperties.indexOf(i),
              1
            );
          }
        });
      }
    },
  },

  async created() {
    this.basicRegInfo = this.$store.state["rider"].riderInfo;
  },
};
</script>

<style scoped>
.category-container {
  padding: 0% 5%;
}
.category-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.city-area {
  margin-top: 3%;
}

#food {
  display: flex;
  margin: 10px;
}

ul {
  padding-left: 0%;
  /* margin-top: 30px; */
}

.category-container p {
  padding-top: 2%;
}

span {
  color: #04030f;
}

.controllers {
  padding-top: 10%;
  display: flex;
  justify-content: space-between;
}

.prev-button button {
  background-color: #fff !important;
  background: #ffffff;
  border: 0.5px solid #43424c;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  color: rgba(133, 77, 39, 1);
  padding: 8px 15px;
}

#btn {
  background-color: rgba(133, 77, 39, 1);
  color: #fff;
}
</style>
