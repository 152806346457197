<template>
  <nav id="background" class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid pt-md-4 py-3" id="whole-container">
      <router-link to="/"><img :src="MyLogo" height="30"/></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul id="menus" class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">About</a>
          </li>

          <li class="nav-item" id="m-item" @click="vendor()">
            <a class="nav-link active" aria-current="page">Become a Vendor</a>
          </li>

          <li class="nav-item" @click="rider()">
            <a class="nav-link active" aria-current="page">Become a Rider</a>
          </li>
        </ul>

        <div id="cart-icon" class="d-flex">
          <router-link id="btn" v-show="user" class="btn btn" to="/login"
            >Login</router-link
          >

          <!-- show when loggedIn -->
          <div v-show="loggedIn" id="dropdown" class="btn-group">
            <div
              class="px-4 pb-3 fs-6 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ username }}
            </div>
            <ul class="dropdown-menu">
              <li>
                <router-link id="dash" to="/">Home</router-link>
              </li>
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li>
                <div class="dropdown-item" @click="dashboard()">Dashboard</div>
              </li>
              <li>
                <a @click="logOut()" class="dropdown-item">Logout</a>
              </li>
            </ul>
          </div>
          <!-- ! show when logged in -->

          <i
            id="cart-items"
            @click="showCart()"
            class="fas fa-shopping-cart"
          ></i>
          <span v-show="CartNumberIfnotLoggedIn" class="cart-count">{{
            cartItemCount || 0
          }}</span>
          <span v-show="CartNumberIfLoggedIn" class="cart-count">{{
            cartItemCount
          }}</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "Header",
  data() {
    return {
      MyLogo: require("../../assets/foodplace.png"),
      user: true,
      loggedIn: false,
      CartNumberIfLoggedIn: false,
      CartNumberIfnotLoggedIn: true,
      username: "",
      cartItems: "",
      loggedInRole: "",
      vendor_list_id: "",
    };
  },

  methods: {
    ...mapActions({
      getOut: "auth/signOut",
      vendorAnalysis: "vendor/analysis",
      userOrder: "order/userOrder",
      vendorListId: "vendor/getVendor",
    }),

    ...mapGetters({
      totalItem: "general/cartItemsCount",
    }),

    logOut() {
      this.getOut();
      router.push({ name: "Home" });
      this.$store.state["auth"].token = null;
      localStorage.removeItem("pass");
      localStorage.removeItem("user");
      localStorage.removeItem("vuex");
      localStorage.removeItem("role");
      this.loggedIn = false;
      this.user = true;
    },

    showCart() {
      router.push({ name: "checkout" });
    },

    // check role to display dashboard
    dashboard() {
      if (this.loggedInRole === "user") {
        router.push({ name: "Dashboard" });
      } else if (this.loggedInRole === "vendor") {
        router.push({ name: "vendor-dashboard" });
      } else {
        router.push({ name: "login" });
      }
    },

    vendor() {
      router.push({ name: "vendor" });
    },

    rider() {
      router.push({ name: "rider" });
    },
  },

  async created() {
    if (this.$store.state["auth"].token !== null) {
      this.loggedIn = true;
      this.user = false;
      this.CartNumberIfLoggedIn = true;
      this.CartNumberIfnotLoggedIn = false;
    } else {
      this.loggedIn = false;
      this.user = true;
      this.CartNumberIfLoggedIn = false;
      this.CartNumberIfnotLoggedIn = true;
    }
    this.username = this.$store.state["auth"].user?.first_name;
    this.loggedInRole = this.$store.state["auth"].user?.role;

    // call if only vendor is logged in
    if (
      this.$store.state["auth"].token !== null &&
      this.$store.state["auth"].user.role == "vendor"
    ) {
      this.vendorListId().then((data) => {
        if (data == undefined) {
          this.vendorAnalysis();
        }
      });
    }

    // call if only user is logged in
    if (
      this.$store.state["auth"].token !== null &&
      this.$store.state["auth"].user.role == "user"
    ) {
      this.userOrder();
    }

    // save cart item count to local storage.
    const item = this.totalItem();
    localStorage.setItem("cart", item);
    this.cartItems = localStorage.getItem("cart");
  },

  computed: {
    cartItemCount() {
      return this.totalItem();
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .navbar-collapse {
    background: #854D27;
    border-radius: 8px;
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
</style>
<style scoped>
#background {
  background-color: #f8e9f2;
}

#logo {
  color: #854d27;
  font-weight: 600;
}

#cart-items {
  cursor: pointer;
}

#dash {
  text-decoration: none;
  color: black;
  margin-left: 15px;
}

#btn {
  color: #854d27;
  padding: 3px 20px;
  border: 1px solid #854d27;
  outline-color: #854d27 !important;
  margin-right: 20%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  cursor: pointer;
}

#cart-icon i {
  padding-top: 8px;
  color: #854d27;
}

#menus {
  padding-left: 15%;
}

#menus li {
  padding-right: 0% 15%;
}

#menu a {
  color: #43424c;
}

#whole-container {
  margin: 0px 6.3%;
}

.cart-count {
  font-size: 15px;
  color: #854d27;
  padding: 0 5px;
  margin-left: -1px;
  background: #fff;
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

.nav-item a {
  padding: 8px 20px;
  margin-right: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #whole-container {
    margin: 0px 20px;
  }
  #menus {
    padding-left: 0%;
  }

  #btn {
    margin-right: 10%;
  }
}
</style>
