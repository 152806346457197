const rider = [
  {
    path: "/Rider",
    name: "rider",
    component: () => import("../../layouts/RiderPages/Rider.vue"),
    children: [
      // {
      //     path:'/request-transcript',
      //     component: () => import('./NewTranscript.vue'),
      // },
      // {
      //     path:'/request-certificate',
      //     component: () => import('./RequestCertificate.vue'),
      // }
    ],
  },
];

export default rider;
