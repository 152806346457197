<template>
  <div>
    <Header />
    <div class="container my-5" id="nav">
      <div class="row">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
        />
        <div class="col-md-8">
          <h5 class="fw-bold">Complete Order</h5>
          <p>Review your order(s)</p>
          <div class="review-order">
            <div class="product-container">
              <div class="row px-2" v-for="(item, index) in cartItem" :key="index">
                <div class="col-4 p-3">
                  <img
                    :src="item.product.image"
                    alt="menuImage"
                    class="img-fluid rounded float-start"
                  />
                </div>
                <div class="col-8">
                  <div class="p-name pt-4">
                    <div class="f-container">
                      <div class="food-name fs-5 fw-bold">{{ item.product["name"] }}</div>
                      <div class="food-descr">
                        {{ item.product["description"] }}
                      </div>
                      <div class="f-price">&#8358;{{ item.product["price"] }}</div>
                      <div class="mt-4 row">
                        <div class="pro-price col-4">
                          &#8358;{{ item.product["price"] * item.quantity }}
                        </div>
                        <div class="qty col-3">
                          <div @click="decreaseQty(item.product)" class="controller">
                            -
                          </div>
                          <div class="no-qty">{{ item.quantity }}</div>
                          <div @click="increaseQty(item.product)" class="controller">
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    <div @click="removeItem(item.product)" class="remove px-3">
                      <span class="px-1"
                        ><img :src="cancel" alt="" class="img-thumbnail"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-8">
              <div class="text-accent fw-bold fs-5">Summary</div>
              <div class="card">
                <div class="card-body border rounded">
                  <div class="row">
                    <div class="col-6">
                      <div>Subtotal</div>
                    </div>
                    <div class="col-6">
                      <div class="text-right fw-bold">&#8358;{{ totalprice }}</div>
                    </div>
                    <div class="col-6">
                      <div>Delivery Fee</div>
                    </div>
                    <div class="col-6">
                      <div class="text-right fw-bold">&#8358;350</div>
                    </div>
                    <div class="col-12">
                      <hr />
                    </div>
                    <div class="col-6">
                      <div>Total Amount</div>
                    </div>
                    <div class="col-6">
                      <div class="text-right fs-5 fw-bold text-accent">
                        &#8358;{{ totalprice + delivery_fee }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact-id" class="col-md-4">
          <h5>Contact Address</h5>
          <div class="card p-3 pb-0">
            <div class="row">
              <div class="col-4 mb-1">
                <div>Full Name:</div>
              </div>
              <div class="col-8 mb-1">
                <div class="text-right fw-bold">
                  <span v-show="loggedInUser" class="address-details"
                    >{{ currentUser?.first_name }} {{ currentUser?.last_name }}</span
                  >
                  <span v-show="userNotLoggedIn" class="address-details">
                    XXXXXXXXXX
                  </span>
                </div>
              </div>
              <div class="col-4 mb-1">
                <div>Email:</div>
              </div>
              <div class="col-8 mb-1">
                <div class="text-right fw-bold">
                  <span v-show="loggedInUser" class="user-email">{{
                    currentUser?.email
                  }}</span>
                  <span v-show="userNotLoggedIn" class="user-email"> XXXXXXXXXX </span>
                </div>
              </div>
              <div class="col-4">
                <div>Phone No:</div>
              </div>
              <div class="col-8">
                <div class="text-right fw-bold">
                  <span v-show="loggedInUser" class="address-details">{{
                    currentUser?.phone
                  }}</span>
                  <span v-show="userNotLoggedIn" class="address-details">
                    XXXXXXXXXX
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="enter-address">
            <h5>Delivery Address</h5>
            <div class="mb-4">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Address"
                v-model="address"
              />
            </div>
            <!--div id="location-info">
            <div>
              <div class="form-check">
                <input ref="autocomplete" class="form-check-input" type="checkbox" value="" />
              </div>
            </div>
            <div id="location-img"><img :src="location" alt="" /></div>
            <div id="location-address">
              Utako Motor Parking Grage Utako, Abuja
            </div>
          </div-->

            <div class="payment-info">
              <div class="method-container">
                <div class="payment-container">
                  <div class="card-img"><img :src="card" alt="" /> Pay with Card</div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-info">
              <div class="method-container">
                <div class="payment-container">
                  <div class="transave-img">
                    <img :src="transfer" alt="" /> Bank Transfer
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      value="bank transfer"
                      @change="onChange($event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-info">
              <div class="method-container">
                <div class="payment-container">
                  <div class="cash-img">
                    <img :src="cash" alt="" style="width: 50px" /> Pay Cash on Delivery
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      value="cod"
                      @change="onChange($event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-info">
              <div class="method-container">
                <div class="payment-container">
                  <div class="transave-img"><img :src="transave" alt="" /> Transave</div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <template v-if="cashPayment == 'bank transfer'">
              <Button
                id="button"
                data-bs-toggle="modal"
                data-bs-target="#bankModal"
                buttonText="COMPLETE ORDER"
              />
            </template>
            <template v-else>
              <Button id="button" @click="pay" buttonText="COMPLETE ORDER" />
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Bank Account Modal -->
    <div
      class="modal fade"
      id="bankModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-5">
            <div class="">
              <h4 class="text-center text-accent fw-bolder mb-3">Bank Transfer</h4>

              <div class="fw-bold mb-2">Bank Details:</div>
              <div class="row">
                <div class="col-md-6">Account Number:</div>
                <div class="col-md-6 fw-bold">20984748484</div>
                <div class="col-md-6">Account Name:</div>
                <div class="col-md-6 fw-bold">Foodplace Technologies</div>
                <div class="col-md-6">Bank Name:</div>
                <div class="col-md-6 fw-bold">Fidelity Bank</div>
              </div>
              <div class="text-center row">
                <div class="col-md-6">
                  <Button id="button" @click="pay" buttonText="CONFIRM PAYMENT" />
                </div>
                <div class="col-md-6 d-grid pt-2">
                  <button class="btn btn-danger" data-bs-dismiss="modal" id="close">CANCEL</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../ReusableUI/button.vue";
import Header from "../NavBar.vue";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";

export default {
  name: "cart",
  data() {
    return {
      product: require("../../../assets/product1.png"),
      product2: require("../../../assets/product2.png"),
      product3: require("../../../assets/product3.png"),
      location: require("../../../assets/location.png"),
      card: require("../../../assets/card.png"),
      transave: require("../../../assets/transave.png"),
      cash: require("../../../assets/cash.png"),
      transfer: require("../../../assets/Delivered.png"),
      cancel: require("../../../assets/cancel.png"),
      cartItem: [],
      data: [],
      cart: [],
      address: "",
      autocomplete: "",
      cashPayment: "",
      userInApp: "",
      loggedInUser: false,
      userNotLoggedIn: true,
      isLoading: false,
      fullPage: true,
      vendor_list_id: "",
      filteredId: "",
      delivery_fee: 350.0,
      form: {},
    };
  },
  components: {
    Header,
    Button,
    Loading,
  },

  methods: {
    ...mapGetters({
      total: "general/cartTotalPrice",
      user: "auth/getUser",
    }),

    ...mapActions({
      remove: "general/removeProduct",
      increaseCartQuantity: "general/increaseQty",
      decreaseCartQuantity: "general/decreaseQty",
      order: "order/placeOrder",
    }),

    // get radio button
    onChange(event) {
      this.cashPayment = event.target.value;
    },

    pay() {
      if (this.userInApp == null) {
        router.push({ name: "login" });
      } else {
        // gather cart items
        let prod = this.cartItem.map((c) => {
          return {
            menu_id: c.product.id,
            quantity: c.quantity,
            price: c.product.price,
          };
        });
        this.isLoading = true;
        // place order
        const data = {
          user_id: this.currentUser.id,
          name: this.currentUser.first_name,
          mobile: this.currentUser.phone,
          vendor_list_id: this.filteredId,
          address: this.address,
          payment_method: this.cashPayment,
          delivery_charge: this.delivery_fee,
          grand_total: this.totalprice,
          menu_order: prod,
        };

        if (data.payment_method == "" || data.address == "") {
          Swal.fire(
            {
              icon: "error",
              title: "Oops...",
              text: "Kindly Enter Address or Select payment method",
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              });
            }
          );
          this.isLoading = false;
          return;
        } else {
          this.order(data)
            .then((data) => {
              if (data == undefined) {
                this.isLoading = false;
                this.state = "";
                this.$store.state["general"].cartItems = [];
                router.push({ name: "Home" });
              } else {
                this.isLoading = false;
              }
            })
            .catch(() => {
              this.isLoading = false;
              Swal.fire({
                icon: "error",
                title: "Server Error",
                text:
                  "Something went wrong, it's not you, it's us, please hang on while we work on it.",
              });
            });
        }
      }
      document.getElementById('close').click();
    },

    removeItem(product) {
      const productId = product.id;
      this.remove({
        productId,
      });
    },

    increaseQty(product) {
      const productId = product.id;
      this.increaseCartQuantity({
        productId,
      });
    },

    decreaseQty(product) {
      const productId = product.id;
      this.decreaseCartQuantity({
        productId,
      });
    },
  },

  async created() {
    this.cartItem = this.$store.state["general"].cartItems;
    this.userInApp = this.$store.state["auth"].token;
    if (this.userInApp === null) {
      this.userNotLoggedIn = true;
    } else {
      this.userNotLoggedIn = false;
      this.loggedInUser = true;
    }
    this.filteredId = localStorage.getItem("vendor_list_id");
  },

  computed: {
    totalprice() {
      return this.total();
    },

    currentUser() {
      return this.user();
    },
  },
};
</script>

<style scoped>
.text-accent {
  color: #854d27;
}

.cart-wrapper {
  /* padding-top: 0.5%; */
}

#nav {
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.remove {
  color: red;
  cursor: pointer;
}

.prod-image img {
  width: 80%;
  height: auto;
  margin-left: 20px;
}

.cart-wrapper h5 {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
}

#contact-id {
  margin-top: 5%;
}

.cart-wrapper p {
  color: #43424c;
  font-weight: 500;
}

.contact-address {
  background-color: #fff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 15px;
}

.address-details {
  padding-left: 10%;
}

.user-email {
  margin-left: 10%;
  /* margin: 15px 0px; */
}

.email-template {
  padding: 4% 0%;
}

.review-order {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: rgba(253, 242, 235, 1);
}

#pro-details {
  display: flex;
  padding: 5px;
}

#LoginInput {
  outline: none !important;
  box-shadow: none;
  background-color: #fdf2eb;
  border-color: rgb(196, 192, 192);
}

#pro-details img {
  padding-top: 10px;
  padding-left: 10px;
}

.cart-summmary {
  display: flex;
  margin-top: 5%;
}

.price {
  padding-left: 10%;
  font-weight: 600;
}

.sub-total-price {
  padding-left: 16%;
  font-weight: 600;
}

.qty {
  display: flex;
  justify-content: space-between;
}

.cart-summmary {
  display: flex;
  padding: 2% 5%;
}

.summary-background {
  margin-top: 20px;
  background: #fdf2eb;
  box-shadow: 0px 1.5px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.qty {
  display: flex;
  font-size: 13px;
}

.controller {
  background: #ffffff;
  margin: 0px 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3px 10px;
  cursor: pointer;
}

.cart-summmary span img {
  padding-left: 10px;
}

.no-qty {
  padding-top: 4px;
}

.pro-price {
  font-weight: 600;
  color: #04030f;
}

.amount-container {
  /* text-align:right; */
  margin-top: 20%;
  padding: 0px 20px;
  color: #04030f;
  font-weight: 500;
}

.summary-wrapper {
  text-align: right;
}

.sub-total {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: #04030f;
  font-weight: 500;
}

.total {
  display: flex;
  width: 100%;

  justify-content: flex-end;
}

.enter-address {
  margin-top: 7%;
}

#location-info {
  display: flex;
}

#location-img {
  padding-right: 3%;
}

.payment-container {
  display: flex;
  justify-content: space-between;
}

.payment-info {
  margin-top: 5%;
  padding: 3%;
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.card-img img {
  padding-right: 20px;
}

.cash-img img {
  padding-right: 20px;
  width: 32%;
}

.transave-img img {
  padding-right: 20px;
  width: 32%;
}

.row {
  margin-bottom: 5%;
}

#button {
  margin-top: 3%;
}

.rounded {
  border-radius: 10px;
}
.text-right {
  text-align: right;
}
</style>
