<template>
  <div class="background">
    <div class="logo">
      <div class="logo-header">
        <p>Welcome to</p>
        <img class="img-fluid" :src="MyLogo" alt="logo" />
      </div>
    </div>
    <div>
      <div class="chef">
        <img class="img-fluid" :src="hamburger" alt="chef" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpFlyer",
  data() {
    return {
      MyLogo: require("../../../assets/foodhere_white.png"),
      hamburger: require("../../../assets/hamburger.png"),
    };
  },
};
</script>

<style scoped>
.logo-header p {
  color: #fff;
}

.background {
  background-color: rgba(133, 77, 39, 1);
  height: 100vh;
  width: 100%;
  text-align: center;
}

.logo img {
  width: 30%;
  height: auto;
  /* padding-top:10%; */
}

.logo-header {
  padding-top: 10%;
}

.chef img {
  width: 75%;
  /* padding-top:7%; */
  height: auto;
  /* margin-left:10%; */
  /* text-align: center;
    align-content: center; */
}
</style>
