<template>
  <div class="left-sidebar">
    <div>
      <h4>My Account</h4>
      <div class="mt-5" id="styles">
        <div mat-list-item active-class="active-p" id="nav-menu" class="menu">
          <router-link to="/dashboard" class="link" active-class="active-p">
            <img :src="MyLogo" alt="history" :class=" $route.path == '/dashboard' ? '' : 'filter-img' " />
            Order Histories
          </router-link>
        </div>
        <div mat-list-item id="nav-menu" class="menu">
          <router-link
            to="/payment"
            class="link"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="payment" alt="payment" :class=" $route.path == '/payment' ? '' : 'filter-img' "/>
            Payment Method
          </router-link>
        </div>

        <div id="nav-menu" class="menu">
          <router-link
            to="/track"
            class="link"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="track" alt="history" :class=" $route.path == '/track' ? '' : 'filter-img' "/>
            Track Order
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/restaurant"
            id="nav-anchor"
            class="link"
            active-class="active-p"
          >
            <img :src="restaurants" alt="restaurants" :class=" $route.path == '/restaurant' ? '' : 'filter-img' "/>
            Restaurants
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/vendor-page"
            id="nav-anchor"
            class="link"
            active-class="active-p"
          >
            <img :src="vendor" alt="vendor" :class=" $route.path == '/vendor-page' ? '' : 'filter-img' "/>
            Become a Vendor
          </router-link>
        </div>

        <div id="nav-menu" class="menu">
          <router-link
            to="/rider-reg"
            id="nav-anchor"
            class="link"
            active-class="active-p"
          >
            <img :src="rider" alt="vendor" :class=" $route.path == '/rider-reg' ? '' : 'filter-img' "/>
            Become a Rider
          </router-link>
        </div>

        <div id="nav-menu" class="menu">
          <router-link
            to="/profile"
            class="link"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="profile" alt="profile" :class=" $route.path == '/profile' ? '' : 'filter-img' "/>
            My Profile
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/review"
            id="nav-anchor"
            class="link"
            active-class="active-p"
          >
            <img :src="review" alt="profile" :class=" $route.path == '/review' ? '' : 'filter-img' "/>
            Reviews
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      MyLogo: require("../../assets/order.png"),
      payment: require("../../assets/payment.png"),
      track: require("../../assets/track.png"),
      profile: require("../../assets/profile.png"),
      restaurants: require("../../assets/restaurants.png"),
      vendor: require("../../assets/vendor.png"),
      review: require("../../assets/reviews.png"),
      rider: require("../../assets/b-rider.png"),
    };
  },
};
</script>

<style scoped>
.filter-img {
  filter: grayscale(200%)
}

.menu img {
  padding-right: 20px;
}

.left-sidebar h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 15%;
  font-weight: 600;
}

.active-p {
  background-color: rgba(240, 239, 245, 1);
  border-radius: 4px;
  color: white;
}

#nav-anchor {
  text-decoration: none;
  font-size: 14px;
}

#nav-menu {
  padding-top: 13px;
  padding-bottom: 13px;
}

.wrapper i {
  color: #fff;
}

.link {
  text-decoration: none;
  font-size: 14px;
  padding: 4% 5%;
  color: rgba(133, 77, 39, 1);
}

#styles a {
  color: rgba(67, 66, 76, 1);
}

.is-active {
  background-color: rgb(174, 171, 171);
}
</style>
