<template>
  <div class="das-container">
    <h4>Restaurants</h4>
    <template v-if="availableRestaurants.length > 0">
      <p class="mt-4">Recent restaurants ordered from</p>
      <div class="details">
        <div class="img">
          <img :src="restaurant" alt="" />
        </div>
        <div class="date">31 Aug 2021</div>
        <div class="time">03:25</div>
      </div>

      <div
        class="card"
        v-for="(restaurant, index) of availableRestaurants"
        :key="index"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <img
                :src="restaurant.image
                "
                class="card-img-top rounded"
                alt="restaurant image"
              />
            </div>
            <div class="col-md-10">
              <div class="order-details">
                <div>
                  <div class="res-name">{{ restaurant?.business_name }}</div>
                </div>
                <!--div>
                  <div class="order-status">Delivered</div>
                </div-->
              </div>
              <div class="food-details mt-2">
                African Dishes, Sushi, Continental, Nigerian, Cousines, Pasteries,
                Fruites, drinks, Snacks, American, Outdoor and indoor cooking
              </div>
              <div class="food-details mt-2">
                Location:
                <span class="address fs-6">{{ restaurant.business_address }}</span>
              </div>
              <div class="star-rating">
                <star-rating v-model:rating="rating" v-bind:star-size="20" :increment="0.5" :rating="4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center my-5 py-5 text-muted">
        <div class="my-5 py-5">
          <div class="fs-2"><i class="fa-solid fa-face-sad-tear"></i></div>
          <div class="mt-2">Nothing to see here, check back later</div>
        </div>
      </div>
    </template>
  </div>

  <!-- </div> -->
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "Sidebar",
  data() {
    return {
      restaurant: require("../assets/restaurants.png"),
      product: require("../assets/soup.png"),
      availableRestaurants: [],
    };
  },

  components: {
    StarRating,
  },

  async created() {
    this.availableRestaurants = this.$store.state["vendor"].restaurants;
  },
};
</script>
<style> 
  .vue-star-rating > span.vue-star-rating-rating-text {
    margin-top: 10px !important;
    font-weight: bold;
  }
</style>
<style scoped>


.row {
  display: flex;
}
.card {
  margin-top: 30px;
}
.address {
  padding-left: 5%;
}
.das-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.das-container p {
  padding-top: 2%;
}

.details {
  display: flex;
}

.img img {
  width: 80%;
}

.img {
  background-color: rgba(241, 222, 209, 1);
  padding: 5px;
  border-radius: 50%;
  padding-left: 8px;
}

.food-details {
  padding-top: 5px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #43424c;
}

.date {
  padding-top: 7px;
  padding-left: 3%;
}

.time {
  padding-top: 7px;
  padding-left: 3%;
}

.order-details {
  display: flex;
  /* padding:0px 2%; */
  justify-content: space-between;
  width: 100%;
}

.res-name {
  font-weight: 600;
}

.order-status {
  color: rgba(133, 77, 39, 1);
  font-weight: 500;
}
</style>
