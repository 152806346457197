<template>
  <div class="btn">
    <button v-on="listeners" class="btn btn-block" type="submit">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    buttonText: {
      type: String,
      default: () => "Label",
    },
  },
};
</script>

<style scoped>
.btn {
  width: 100%;
  color: #fff;
  padding: 0.35rem 0px !important;
  background-color: rgba(133, 77, 39, 1);
  border: none;
  outline: none;
}

.btn {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}
</style>
