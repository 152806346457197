<template>
  <div>
    <Header />
    <main class="layout-container">
      <div class="row">
        <div class="col-md-3 col-12">
          <Sidebar />
        </div>
        <div id="body" class="col-md-9 col-12">
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/components/DashboardLayout/Header";
import Sidebar from "@/components/DashboardLayout/Sidebar";

export default {
  name: "Layout",
  components: { Header, Sidebar },
};
</script>

<style>
.layout-container {
  padding: 0% 6%;
  background-color: #fafaff;
}

#body {
  overflow-y: scroll;
  height: calc(100vh - 60px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */
</style>
