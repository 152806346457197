<template>
  <div class="row">
    <div id="flyer" class="col-md-5">
      <SignUpFlyer />
    </div>
    <div id="input-container" class="col-md-7">
      <div class="toGo">
        <div class="header">
          <h4>Sign Up</h4>
          <p>Create account to track your orders</p>
        </div>
        <SignUpInput />

        <div class="register">
          <div class="ask">Already have an Account?</div>
          <div class="sign-in">
            <router-link class="sign-up" to="/login">Sign In</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpFlyer from "../SignUp/SignUpFlyer.vue";
import SignUpInput from "./SignUpInput.vue";
// import router from "@/router";

export default {
  components: {
    SignUpFlyer,
    SignUpInput,
  },

  methods: {},
};
</script>

<style scoped>
.sign-up {
  color: rgba(133, 77, 39, 1);
  text-decoration: none;
}

.row {
  --bs-gutter-x: 0rem;
}

.header {
  text-align: center;
}

router-link {
  text-decoration: none;
  color: rgba(133, 77, 39, 1);
}

.header h4 {
  color: rgba(133, 77, 39, 1);
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
}

.ask {
  color: #04030f;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.register {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}

.header h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #04030f;
}

.toGo {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header p {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 4%;
}
#input-container {
  padding: 0px 14%;
}

@media only screen and (max-width: 800px) {
  .col-md-5 {
    display: none;
  }

  #input-container {
    margin-top: 20%;
  }
}
</style>
