<template>
  <div>
    <div>Auth Layout</div>
    <slot />
  </div>
</template>

<script>
export default {
  // setup() {
  // },
};
</script>
