import axios from "axios";
import Swal from "sweetalert2";
// import store from "../../../src/store/Module/vendor";

export default {
    namespaced: true,

    state: {
        user: null,
        vendor: null,
        vendorOrders: [],
        restaurants: [],
        analysis: null,
        singleVendorDetails: [],
    },

    mutations: {
        USER_INFO(state, vendor) {
            state.vendor = vendor;
        },

        USER_VENDORS(state, vendor) {
            state.vendor = vendor;
        },

        VENDORS_ORDERS(state, vendorOrders) {
            state.vendorOrders = vendorOrders;
        },

        VENDOR_ANALYSIS(state, analysis) {
            state.analysis = analysis;
        },

        SINGLE_INFO(state, info) {
            state.singleVendorDetails = info;
        },

        AVAILABLE_RESTAURANTS(state, restaurants) {
            state.restaurants = restaurants;
        },
    },

    getters: {
        availableUserVendors(state) {
            return state.vendor;
        },

        VendorOrders(state) {
            return state.vendorOrders;
        },

        restaurants(state) {
            return state.restaurants;
        },

        vendor_info(state) {
            return state.singleVendorDetails;
        },
    },

    // Register Vendor
    actions: {
        async insertVendor(_, credentials) {
            return axios.post(`vendor/store`, credentials).then((response) => {
                if (response.data.code == 201) {
                    Swal.fire(
                        response.data.message,
                        "Registration Sucessful. Pending Verification, your vendor registration is being reviewed.You will receive a notification on successful approval.",
                        "success"
                    );
                } else {
                    Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.message,
                        },
                        (err) => {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: err,
                            });
                        }
                    );
                }
            });
        },

        // create menu action
        async createMenu(_, data) {
            return axios.post(`vendor/menu`, data).then((response) => {
                if (response.data.code == 200) {
                    Swal.fire("Success", response.data.message, "success");
                } else {
                    Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.message,
                        },
                        (err) => {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: err,
                            });
                        }
                    );
                }
            });
        },

        // get vendor menu
        async getMenus(payload) {
            payload = "702f5d72-88d0-43fd-a61c-887981dafca2"
            await axios.get(`vendor-menus/${ payload }`).then((res) => {
                return res.data.data
            })
        },

        // get vendors and vendor_list_id action
        async getVendor({ dispatch }, user) {
            return axios.get(`vendor/details/${user}`).then((response) => {
                let categoryArray;
                const vendorList = response.data.data;
                for (let i = 0; i < vendorList.length; i++) {
                    categoryArray = vendorList[i].vendor_list;
                    for (let j = 0; j < vendorList[i].menu.length; j++) {

                        localStorage.setItem(
                            "vendor_list_id",
                            vendorList[i].menu[j].vendor_list_id
                        );
                    }
                }
                dispatch("VENDORS", categoryArray)
            });
        },

        // available restaurants
        async restaurants({ dispatch }) {
            return axios.get(`/vendor/restaurants`).then((response) => {
                dispatch("RESTAURATS", response.data.restaurants);
            });
        },

        // single vendor details Action
        async getVendorFullDetails({ dispatch }) {
            const vendor_id = localStorage.getItem("id");
            return axios.get(`vendor-menus/${vendor_id}`).then((response) => {
                dispatch("commit_details", response.data.data);
            });
        },

        // get analysis
        async analysis({ dispatch }) {
            let vendor_list_id = localStorage.getItem("vendor_list_id");
            return axios
                .get(`/vendor/${vendor_list_id}/analysis`)
                .then((response) => {
                    dispatch("vendorAnalysis", response.data);
                });
        },

        // new vendor orders
        async getOrders({ dispatch }, vendor_list_id) {
            //let vendor_list_id = localStorage.getItem("vendor_list_id");
            let orderArray = [];
            return axios.get(`/vendor/orders/${vendor_list_id}`).then((response) => {
                const vendorOrderList = response.data.data;
                for (let i = 0; i < vendorOrderList.length; i++) {
                    const menus = vendorOrderList[i].menu;
                    if (menus) {
                        menus.map((menu) => {
                            if (menu.menu_order.length) {
                                const orders = menu.menu_order.map((o) => {
                                    return {
                                        ...o,
                                        image: menu.image,
                                        name: menu.name,
                                        status: menu.status,
                                    };
                                });
                                orderArray = [...orderArray, ...orders];
                            }
                        });
                    }
                }
                dispatch("vendorOrders", orderArray);
            });
        },

        // commit analysis to store
        async vendorAnalysis({ commit }, anaysis) {
            commit("VENDOR_ANALYSIS", anaysis);
        },

        // commit vendor orders
        async vendorOrders({ commit }, orders) {
            commit("VENDORS_ORDERS", orders);
        },

        // commit vendors
        async VENDORS({ commit }, vendors) {
            commit("USER_VENDORS", vendors);
        },

        // commit restaurant to store.
        async RESTAURATS({ commit }, restaurants) {
            commit("AVAILABLE_RESTAURANTS", restaurants);
        },

        // commit vendor single details to store
        async commit_details({ commit }, info) {
            commit("SINGLE_INFO", info);
        },
    },
};