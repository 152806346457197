import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import Swal from "sweetalert2";

export default {
  namespaced: true,

  state: {
    token: null || "",
    user: null,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    VENDOR_INFO(state, vendor) {
      state.vendor = vendor;
    },
  },

  getters: {
    isLoggedIn: (state) => {
      return state.token;
    },

    getUser: (state) => {
      return state.user;
    },
  },

  // login
  actions: {
    async login({ dispatch }, credentials) {
      return axios.post(`login`, credentials).then((response) => {
        if (response.data.code === 200) {
          const token = response.data.access_token;
          const user = response.data.user;
          localStorage.setItem("pass", JSON.stringify(token));
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("role", user.role);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          dispatch("attempt", response.data.access_token);
          dispatch("add", response.data.user);
        }
        return response.data;
      });
    },

    async signOut() {
      return axios.post(`logout`).then((response) => {
        if (response.data.code == 200) {
          Swal.fire(
            "Thank you for coming around",
            response.data.message,
            "success"
          );
        } else {
          Swal.fire(
            {
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err,
              });
            }
          );
        }
      });
    },

    // commit token
    async attempt({ commit }, token) {
      commit("SET_TOKEN", token);
    },

    // commit user
    async add({ commit }, user) {
      commit("SET_USER", user);
    },

    // register
    async register(_, payload) {
      return axios.post(`register`, payload).then((resp) => {
        if (resp.status === 201) {
          // console.log(resp);
        }
        return resp.data;
      });
    },

    // Resend verification mailer
    async sendVerificationMail() {
      const token = localStorage.getItem("pass");
      const headers = {
        Authorization: "Bearer " + token,
      };
      return axios
        .post(`email/verification-notification`, { headers })
        .then((response) => {
          if (response.data.message != null) {
            Swal.fire(
              "Please check your email",
              response.data.message,
              "success"
            );
          } else {
            Swal.fire(
              {
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              },
              (err) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: err,
                });
              }
            );
          }
        });
    },
  },
  plugins: [createPersistedState()],
};
