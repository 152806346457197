<template>
  <div class="das-container">
    <h4>My Timeline</h4>
    <template v-if="allUserOrder.length > 0">
      <p>Today</p>
      <div v-for="(order, index) of allUserOrder" :key="index">
        <div class="details">
          <div class="img">
            <img :src="restaurant" alt="" />
          </div>
          <div class="date">{{ order.formartedDate }}</div>
          <div class="time">{{ order.formartedTime }}</div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <img
                  :src="order.menu.image"
                  class="card-img-top rounded"
                  alt="food image"
                />
              </div>
              <div class="col-md-10">
                <div class="order-details">
                  <div class="res-name">
                    {{ order.menu.name }}
                  </div>
                  <div>
                    <div class="order-status">{{ order.status }}</div>
                  </div>
                </div>
                <div class="food-details">
                  <div>{{ order.business_name }}</div>
                </div>
                <div class="food-details">
                  Location:
                  <span class="address">{{ order.business_address }}</span>
                </div>
                <div class="qty_price">
                  <div id="goods_qty">Qty: {{ order.quantity }}</div>
                  <div class="product_price"># {{ order.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center my-5 py-5 text-muted">
        <div class="my-5 py-5">
          <div class="fs-2"><i class="fa-solid fa-face-sad-tear"></i></div>
          <div class="mt-2">Your order list is empty</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      restaurant: require("../assets/restaurants.png"),
      product: require("../assets/soup.png"),
      allUserOrder: [],
    };
  },

  methods: {
    ...mapActions({
      getStates: "general/getState",
      allRestaurants: "vendor/restaurants",
    }),
  },

  async created() {
    this.getStates()
    this.allRestaurants()
    this.allUserOrder = this.$store.state["order"].availableUserOrders
    console.log(this.allUserOrder.length)
  },
}
</script>

<style>
.card {
  margin-top: 30px;
}
.address {
  padding-left: 5%;
}

.qty_price {
  display: flex;
  padding-top: 5px;
  font-size: 14px;
}

#goods_qty {
  margin-right: 10px;
}

.product_price {
  font-weight: bold;
}
.das-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.das-container p {
  padding-top: 2%;
}

.details {
  display: flex;
  margin-top: 12px;
}

.img img {
  width: 80%;
}

.img {
  background-color: rgba(241, 222, 209, 1);
  padding: 5px;
  border-radius: 50%;
  padding-left: 8px;
}

.food-details {
  padding-top: 5px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #43424c;
}

.date {
  padding-top: 7px;
  padding-left: 3%;
}

.time {
  padding-top: 7px;
  padding-left: 3%;
}

.order-details {
  display: flex;
  /* padding:0px 2%; */
  justify-content: space-between;
  width: 100%;
}

.res-name {
  font-weight: 600;
}

.order-status {
  color: rgba(133, 77, 39, 1);
  font-weight: 500;
}
</style>
