const vendor = [{
        path: "/Vendor",
        name: "vendor",
        component: () =>
            import ("../../layouts/VendorPages/Vendor.vue"),
    },
    {
        path: "/Layout",
        component: () =>
            import ("../../layouts/VendorPages/VendorComponents/Layout.vue"),
        beforeEnter: (to, from, next) => {
            const allowUser = localStorage.getItem("role");
            if (allowUser === "vendor") {
                next();
            } else {
                next("/login");
            }
        },
        children: [{
                path: "/Vendor-dashboard",
                name: "vendor-dashboard",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/LandingPage"
                    ),
            },

            {
                path: "/create-restaurant",
                name: "create-restaurant",
                component: () =>
                    import (
                        "@/layouts/VendorPages/VendorComponents/DashboardPages/CreateRestaurant"
                    ),
            },

            {
                path: "/vendor-food-category",
                name: "vendor-food-category",
                component: () =>
                    import (
                        "@/layouts/VendorPages/VendorComponents/DashboardPages/FoodCategory"
                    ),
            },

            {
                path: "/edit-business",
                // name: "edit-business",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/EditBusiness"
                    ),
            },
            {
                path: "/menu",
                // name: "menu",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/menu"
                    ),
            },
            {
                path: "/order",
                // name: "order",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/Orders"
                    ),
            },
            {
                path: "/make-payment",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/Payment"
                    ),
                children: [{
                        path: "/bank",
                        component: () =>
                            import (
                                "../../layouts/VendorPages/VendorComponents/DashboardPages/BankPayment"
                            ),
                    },
                    {
                        path: "/Transave",
                        component: () =>
                            import (
                                "../../layouts/VendorPages/VendorComponents/DashboardPages/Transave"
                            ),
                    },
                ],
            },
            {
                path: "/vendor-review",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/Reviews"
                    ),
            },
            {
                path: "/vendor-rider",
                component: () =>
                    import (
                        "../../layouts/VendorPages/VendorComponents/DashboardPages/Riders"
                    ),
            },
        ],
    },
];

export default vendor;