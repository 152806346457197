<template>
  <div class="category-container">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <h4>Vendor / Categories</h4>
    <p>In this section, give us more detail about your main offering.</p>
    <span>Select your business' main cuisine/brand or Categories</span>

    <div class="row">
      <div class="col-md">
        <ul>
          <li id="food" v-for="(category, index) in categories" :key="index">
            <div class="form-check">
              <input
                class="form-check-input"
                @change="categoryValue($event, category)"
                type="checkbox"
                value="category.id"
                :id="flexCheckDefault + category.name"
              />
            </div>
            <label :for="flexCheckDefault + category.name">{{ category.name }}</label>
          </li>
        </ul>
      </div>

      <!-- buttons -->
      <div class="controllers">
        <div class="prev-button"><button @click="back">PREVIOUS</button></div>
        <div><Button id="btn" @click="Submit" btnText="SUBMIT" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/ReusableUI/SmalllButton.vue";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";

export default {
  name: "FoodCategory",
  props: ["info"],

  data() {
    return {
      selected: [],
      regInfo: "",
      vendorData: [],
      formSubmit: [],
      availableId: [],
      categories: [],
      isLoading: false,
      fullPage: true,
      onCancel: true,
    };
  },

  components: {
    Button,
    Loading,
  },

  methods: {
    ...mapActions({
      registerVendor: "vendor/insertVendor",
      getCategories: 'general/getCategory',
    }),

    ...mapGetters({
      availableCategories: "general/categories",
    }),

    back() {
      router.push({ name: "vendor-page" });
    },

    Submit(event) {
      this.isLoading = true;
      event.preventDefault();
      const {
        business_name,
        email,
        phone,
        state_id,
        business_address,
        city_id,
        image,
      } = this.regInfo;
      var formData = new FormData();
      formData.append("business_name", business_name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("state_id", state_id);
      formData.append("city_id", city_id);
      formData.append("business_address", business_address);
      formData.append("image", image);
      formData.append("longitude", 0.0);
      formData.append("latitude", 0.0);
      this.selected.forEach((item) => {
        formData.append(`category_ids[]`, item);
      });
      this.registerVendor(formData).then((response) => {
        if (response == undefined) {
          this.isLoading = false;
          router.push({ name: "Dashboard" });
        } else {
          this.isLoading = false
        }
      }).catch((error) => {
        this.isLoading = false
        
        console.log()
        if(error.response.status == 500) {
          Swal.fire("Error!", "Internal Serrver Error, please contact an admin", "error")
        } else {
          Swal.fire(
          "Error!", 
          error.response.data.data.image ? error.response.data.data.image[1] : 'There is an error with your input',
          "error")
        }
        
      })
    },

    categoryValue(event, category) {
      if (event.target.checked == true) {
        this.selected.push(category.id);
      } else {
        this.selected.map((i) => {
          if (i == category.id) {
            this.selected.splice(this.selected.indexOf(i), 1);
          }
        });
      }
    },
  },

  async created() {
    this.regInfo = this.$store.state["vendor"];
    this.categories = this.$store.state["general"].categories;
    this.getCategories()
  },
};
</script>

<style scoped>
.category-container {
  padding: 0% 5%;
}
.category-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

#food {
  display: flex;
  margin: 10px;
}

ul {
  padding-left: 0%;
  margin-top: 30px;
}

.category-container p {
  padding-top: 2%;
}

span {
  color: #04030f;
}

.controllers {
  padding-top: 10%;
  display: flex;
  justify-content: space-between;
}

.prev-button button {
  background-color: #fff !important;
  background: #ffffff;
  border: 0.5px solid #43424c;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  color: rgba(133, 77, 39, 1);
  padding: 8px 15px;
}

#btn {
  background-color: rgba(133, 77, 39, 1);
  color: #fff;
}
</style>
