<template>
  <div class="payment-container">
    <h4>Payment Method</h4>
    <p>Account Details</p>
    <form class="form">
      <div>
        <label>Bank Name</label>
        <div class="input-group input-group-lg mb-3">
          <input
            id="input"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>

      <div>
        <label>Account Number</label>
        <div class="input-group input-group-lg mb-3">
          <input
            type="text"
            id="input2"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>

      <div>
        <label>Account Name</label>
        <div class="input-group input-group-lg mb-3">
          <input
            type="text"
            id="input3"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
          />
        </div>
      </div>
      <Button id="btn" @click="save" buttonText="Save" />
    </form>
  </div>
</template>

<script>
import Button from "../components/ReusableUI/button.vue";

export default {
  components: {
    Button,
  },

  methods: {
    save(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
#btn {
  margin-top: 4%;
}
.payment-container {
  padding: 0% 5%;
}
.payment-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.payment-container p {
  padding-top: 2%;
}

form {
  padding-top: 20px;
}

#input {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
  margin-right: 0px;
}

#input2 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#input3 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}
</style>
