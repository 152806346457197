<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  font-family: Nunito Sans;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px #f1f1f1;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border: 2px solid #555555;
}
</style>
