<template>
  <form>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <div class="mb-3 form-group">
      <label for="exampleFormControlInput1" class="form-label"
        >First Name</label
      >
      <input
        type="text"
        v-model="state.first_name"
        class="form-control SignUpInput"
      />
      <span v-if="v$.first_name.$error">
        {{ v$.first_name.$errors[0].$message }}
      </span>
    </div>

    <div class="mb-3 form-group">
      <label for="exampleFormControlInput1" class="form-label">Last Name</label>
      <input
        type="text"
        v-model="state.last_name"
        class="form-control SignUpInput"
      />
      <span v-if="v$.last_name.$error">
        {{ v$.first_name.$errors[0].$message }}
      </span>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">Email</label>
      <input
        type="email"
        v-model="state.email"
        class="form-control SignUpInput"
      />
      <span v-if="v$.email.$error">
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label"
        >Phone Number</label
      >
      <input
        type="number"
        v-model="state.phone"
        class="form-control SignUpInput"
      />
      <span v-if="v$.phone.$error">
        {{ v$.phone.$errors[0].$message }}
      </span>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Password</label
          >
          <input
            type="password"
            v-model="state.password"
            class="form-control SignUpInput"
          />
          <span id="error" v-if="v$.password.$error">
            {{ v$.password.$errors[0].$message }}
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Confirm Password</label
          >
          <input
            type="password"
            class="form-control SignUpInput"
            v-model="state.password_confirmation"
          />
          <span v-if="v$.password_confirmation.$error">
            {{ v$.password_confirmation.$errors[0].$message }}
          </span>
        </div>
      </div>
    </div>
    <Button @click.prevent="signUp" buttonText="Sign Up" />
  </form>
</template>

<script>
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import Button from "../../ReusableUI/button.vue";
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  setup() {
    const state = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
    });

    const rules = computed(() => {
      return {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        phone: { required },
        password: { required, minLength: minLength(6) },
        password_confirmation: { required, sameAs: sameAs(state.password) },
      };
    });

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },

  components: {
    Button,
    Loading,
  },

  validations() {
    return {
      first_name: { required },
      last_name: { required },
      email: { required },
      phone: { required },
      password: { required },
      password_confirmation: { required },
    };
  },

  methods: {
    ...mapActions({
      Register: "auth/register",
    }),

    signUp() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const {
          first_name,
          last_name,
          email,
          phone,
          password,
          password_confirmation,
        } = this.state;
        const data = {
          first_name,
          last_name,
          email,
          phone,
          password: password,
          password_confirmation: password_confirmation,
        };
        this.isLoading = true;
        this.Register(data)
          .then((success) => {
            if (success.code === 201) {
              Swal.fire("Registration Successful", success.message, "success");
              this.isLoading = false;
              router.push({ name: "login" });
            } else if (success.data.phone !== null) {
              Swal.fire(
                "Email or Phone number has already been taken",
                success.message,
                "error"
              );
              this.isLoading = false;
            } else {
              this.isLoading = false;
              Swal.fire(
                {
                  icon: "error",
                  title: "Oops...",
                  text: success.message,
                },
                (err) => {
                  this.isLoading = false;
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err,
                  });
                }
              );
            }
          })
          .catch((error) => {
            console.log(error.response.data.data.phone);
            this.isLoading = false
            Swal.fire({
              icon: "error",
              title: "Validation Error",
              text: error.response.data.data.phone || error.response.data.data.email
            })
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.SignUpInput {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

span {
  color: red;
}
</style>
