import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index";
import store from "./store";
import updateState from "./store/Module/auth";
// import VueChartkick from "vue-chartkick";
// import "chartkick/chart.js";
import axios from "axios";

// Alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/style.css"

// re-assign token upon refresh
const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("pass"));
updateState.state.token = token;
updateState.state.user = user;

if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

// baseURL
axios.defaults.baseURL = "https://api.foodplace.com.ng/api/v1";
// axios.defaults.baseURL = "localhost:8000/api/v1";
// axios.defaults.baseURL = "https://api.foodie.raadaa.com/api/v1/";

createApp(App)
    .use(router)
    .use(VueSweetalert2)
    // .use(VueChartkick)
    .use(store)
    .mount("#app");