<template>
  <div class="das-container">
    <h4>Track Order</h4>
    <template v-if="allOrderHistory.length > 0">
      <p>Today</p>
      <div class="card" v-for="(order, index) of allOrderHistory" :key="index">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div id="inner-card">
                <div class="details">
                  <div class="img">
                    <img :src="restaurant" alt="" />
                  </div>
                  <div class="date">{{ order.formartedDate }}</div>
                  <div class="time">{{ order.formartedTime }}</div>
                </div>

                <div class="food-details row gap-0">
                  <div class="f-img col-md-4 text-center p-0">
                    <img :src="order.menu.image" class="rounded"/>
                  </div>
                  <div class="col-md-8">
                    <!-- <div class="food-type">Sushi and Fruits</div> -->
                    <div class="food-name">{{ order.menu.name }}</div>
                    <div class="py-1">{{ order.menu.description }}</div>
                    <div class="amount">₦ {{ order.menu.price }}</div>
                  </div>
                </div>

                <div class="qty-container col-12">
                  <div class="order">Order Code:</div>
                  <div class="qty">Qty:</div>
                </div>

                <div class="qty-container-no">
                  <div class="order-no">3344675</div>
                  <div class="order-qty">{{ order.quantity }}</div>
                </div>
                <div>
                  <div class="star-rating">
                    <star-rating
                      v-model:rating="rating"
                      v-bind:star-size="20"
                      @rating-selected="setRating"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="tracker1-container">
                <div>
                  <div>
                    <img :src="tracker" alt="" />
                  </div>
                  <div style="padding-left: 17px">
                    <img :src="line2" alt="" />
                  </div>
                </div>
                <div class="tracker-details">
                  <div class="orderStatus1">Order received</div>
                  <div class="orderStatus2">Your order has been received</div>
                  <div class="timer">{{ order.time_acknowledged }}</div>
                </div>
              </div>
              <div class="tracker1-container">
                <div>
                  <div>
                    <img :src="way" alt="" />
                  </div>
                  <div style="padding-left: 17px">
                    <img :src="line2" alt="" />
                  </div>
                </div>
                <div class="tracker-details">
                  <div class="orderStatus1">Order processed</div>
                  <div class="orderStatus2">Order prepared</div>
                  <div class="timer">{{ order.time_processing }}</div>
                </div>
              </div>

              <div class="tracker1-container">
                <div>
                  <div>
                    <img :src="delivered" alt="" />
                  </div>
                  <div style="padding-left: 17px">
                    <img :src="line2" alt="" />
                  </div>
                </div>
                <div class="tracker-details">
                  <div class="orderStatus1">Delivery in Progress</div>
                  <div class="orderStatus2">Order on the way</div>
                  <div class="timer">{{ order.time_dispatched }}</div>
                </div>
              </div>

              <div class="tracker1-container">
                <div>
                  <div>
                    <img :src="confirm" alt="" />
                  </div>
                </div>
                <div class="tracker-details">
                  <div class="orderStatus1">Order Delivered</div>
                  <div class="orderStatus2">Order has been received</div>
                  <div class="timer">{{ order.time_delivered }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Order Review modal -->
      <div v-if="modal">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="modal-head">Review Your Delivery</div>
                    <div class="rating-container">
                      <div class="rate-us">Rate Us</div>
                      <div class="feedback-container">
                        <div class="delivery-q">How is the delivery?</div>
                        <div class="feedback">
                          Your feedback will help improve delivery<br />
                          experience
                        </div>
                      </div>

                      <div class="rating-box">
                        <div class="star-Rating">
                          <star-rating
                            v-model:rating="riderRating"
                            v-bind:star-size="25"
                            @rating-selected="setRating"
                          />
                        </div>
                        <div class="comment-box">
                          <textarea
                            placeholder="Add comment"
                            cols="30"
                            rows="4"
                          ></textarea>
                        </div>
                        <div id="rate" class="d-grid gap-2">
                          <button class="btn btn" type="button">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
    <template v-else>
      <div class="text-center my-5 py-5 text-muted">
        <div class="my-5 py-5">
          <div class="fs-2"><i class="fa-solid fa-face-sad-tear"></i></div>
          <div class="mt-2">Nothing here to track, check back later</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "Sidebar",
  data() {
    return {
      tracker: require("../assets/tracker1.png"),
      way: require("../assets/Onway.png"),
      delivered: require("../assets/Delivered.png"),
      line2: require("../assets/Line2.png"),
      confirm: require("../assets/Confirm.png"),
      restaurant: require("../assets/restaurants.png"),
      food: require("../assets/food.png"),
      rating: 0,
      riderRating: 0,
      allOrderHistory: "",
      modal: false,
    };
  },

  components: {
    StarRating,
    // Tracker,
  },

  methods: {
    setRating: function (rating) {
      this.rating = rating;
      this.riderRating = rating;
    },
  },

  async created() {
    this.allOrderHistory = this.$store.state["order"].availableUserOrders;
    // this.modal = true
  },
};
</script>

<style scoped>
.das-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.modal-head {
  color: #04030f;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 0px;
  margin-left: 40px;
}

.comment-box {
  margin-top: 20px;
}

.comment-box textarea {
  border: none;
  padding: 10px;
  outline: none;
  background: #eeeeee;
  border-radius: 10px;
}

.rating-container {
  text-align: center;
}

.rate-us {
  color: #854d27;
  font-weight: 20px;
  font-weight: 600;
}

.delivery-q {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
}

.feedback-container {
  margin-top: 17px;
}

.star-Rating {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0px 100px;
  text-align: center !important;
  padding: 16px 0px;
  margin-top: 20px;
  padding-left: 40px;
}

#rate {
  margin: 0px 89px !important;
}

#rate button {
  background-color: rgba(133, 77, 39, 1);
  color: #fff;
  margin: 10px 0px;
}

.feedback {
  color: rgba(67, 66, 76, 1);
  font-size: 12px;
}

.das-container p {
  padding-top: 2%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.5s ease-in-out;
}

.modal-wapper {
  display: table-cell;
  vertical-align: middle;
}

.food-details {
  display: flex;
  margin-left: 6px;
}

.food-details img {
  width: 80%;
}

.food-type {
  font-weight: 600;
  font-size: 14px;
  color: #04030f;
}

.amount {
  font-weight: 600;
  font-size: 14px;
  color: #04030f;
}

.food-name {
  font-weight: 600;
  font-size: 14px;
  padding: 0px;
  color: #43424c;
}

#inner-card {
  background-color: #fdf2eb;
  height: 50vh;
  border: 0.5px solid #f8e9f2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.details {
  display: flex;
  padding: 2%;
}

.img img {
  width: 80%;
}

.img {
  background-color: rgba(241, 222, 209, 1);
  padding: 5px;
  border-radius: 50%;
  padding-left: 8px;
}

.date {
  padding-top: 7px;
  padding-left: 3%;
}

.time {
  padding-top: 7px;
  padding-left: 3%;
}

.qty-container {
  padding-top: 20px;
  display: flex;
  font-weight: 10px;
  margin-left: 10px;
}

.qty-container-no {
  display: flex;
  font-weight: 10px;
  margin-left: 10px;
}

.qty {
  margin-left: 30px;
  font-size: 12px;
  color: #43424c;
}

.order {
  font-size: 12px;
  color: #43424c;
}

.order-no {
  font-size: 12px;
  padding-top: 7px;
  color: #43424c;
  font-weight: 600;
}

.order-qty {
  margin-left: 50px;
  font-size: 12px;
  padding-top: 7px;
  color: #43424c;
  font-weight: 600;
}

.star-rating {
  padding: 10px;
}

.tracker1-container {
  display: flex;
}

.orderStatus1 {
  color: rgba(4, 3, 15, 1);
  font-size: 15px;
  font-weight: 600;
}
.orderStatus2 {
  color: rgba(122, 122, 122, 1);
  font-size: 10px;
}

.tracker-details {
  padding-left: 10px;
}

.timer {
  color: rgba(67, 66, 76, 1);
  font-size: 10px;
}
</style>
