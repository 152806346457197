import axios from "axios";
import Swal from "sweetalert2";

export default {
    namespaced: true,

    state: {
        categories: null || "",
        states: null || "",
        cartItems: [],
        menus: [],
    },

    // STATE MUTATIONS
    mutations: {
        AVAILABLE_CATEGORIES(state, categories) {
            state.categories = categories;
        },

        SET_CITIES(state, cities) {
            state.cities = cities;
        },

        SET_STATES(state, states) {
            state.states = states;
        },

        BUSINESS_TYPE(state, businessType) {
            state.businessType = businessType;
        },

        ADD_TO_CART(state, { product, quantity }) {
            let productInCart = state.cartItems.find((item) => {
                return item.product.id === product.id;
            });

            let vendorListID = state.cartItems.find((e) => {
                return e.product.vendor_list_id != product.vendor_list_id;
            });

            if (productInCart) {
                productInCart.quantity += quantity;
                return;
            } else if (vendorListID) {
                return;
            } else {
                state.cartItems.push({ product, quantity });
            }
        },

        REMOVE_FROM_CART(state, product) {
            let items = state.cartItems.map((item) => {
                if (item.product.id == product.productId) {
                    state.cartItems.splice(item, 1);
                }
            });
            return items;
        },

        INCREASE_CART_QTY(state, product) {
            let itemQty = state.cartItems.find((item) => {
                return item.product.id === product.productId;
            });
            if (itemQty) {
                itemQty.quantity++;
            }
        },

        DECREASE_CART_QTY(state, product) {
            let itemQty = state.cartItems.find((item) => {
                return item.product.id === product.productId;
            });
            if (itemQty && itemQty.quantity !== 0) {
                itemQty.quantity--;
            }
        },

        VENDOR_MENUS(state, menus) {
            state.menus = menus;
        },
    },

    // STATE GETTERS
    getters: {
        categories(state) {
            return state.categories;
        },

        availableStates(state) {
            return state.states;
        },

        availableMenus(state) {
            return state.menus;
        },

        itemsInCart(state) {
            return state.cartItems;
        },

        cartItemsCount(state) {
            return state.cartItems?.length
        },

        cartTotalPrice(state) {
            let total = 0.00 // 350; don't know why it's 350 by default
            state.cartItems.forEach((item) => {
                total += item.product.price * item.quantity;
            });
            
            return total
        },

        getMenus(state) {
            return state.menus
        } 
    },

    // STATE ACTIONS
    actions: {
        // categories Action
        async getCategory({ dispatch }) {
            return axios.get(`/categories`).then((response) => {
                dispatch("commited_category", response.data.data);
            });
        },

        // states Action
        async getState({ dispatch }) {
            return axios.get(`vendor/states`).then((response) => {
                dispatch("commited_state", response.data.data);
            });
        },

        // available menus
        async availableMenu({ dispatch }) {
            return axios.get(`get-menus`).then((response) => {
                dispatch("MENUS", response.data.data);
            });
        },

        // Update password
        async updatePasscode(_, data) {
            return axios.put(`update-password`, data).then((response) => {
                this.isLoading = true;
                if (response.data.code == 200) {
                    Swal.fire("Success", response.data.message, "success");
                    this.isLoading = false;
                } else {
                    Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.message,
                        },
                        (err) => {
                            console.log(err);
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: err,
                            });
                        }
                    );
                }
            });
        },

        // update user profile
        async updateProfile(_, data) {
            return axios.put(`user`, data).then((response) => {
                this.isLoading = true;
                if (response.data.code == 200) {
                    Swal.fire("Success", response.data.message, "success");
                    this.isLoading = false;
                } else {
                    Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.message,
                        },
                        (err) => {
                            console.log(err);
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: err,
                            });
                        }
                    );
                }
            });
        },

        // commit menus
        async MENUS({ commit }, menus) {
            commit("VENDOR_MENUS", menus);
        },

        // add item to cart
        async addToProductCart({ commit }, { product, quantity }) {
            commit("ADD_TO_CART", { product, quantity });
        },

        // remove item from cart
        async removeProduct({ commit }, product) {
            commit("REMOVE_FROM_CART", product);
        },

        // increase quantity
        async increaseQty({ commit }, product) {
            commit("INCREASE_CART_QTY", product);
        },

        // increase quantity
        async decreaseQty({ commit }, product) {
            commit("DECREASE_CART_QTY", product);
        },

        // commit categories to store
        async commited_category({ commit }, categories) {
            commit("AVAILABLE_CATEGORIES", categories);
        },

        // commit states to store
        async commited_state({ commit }, states) {
            commit("SET_STATES", states);
        },
    },
};