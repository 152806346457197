<template>
  <form>
    <loading
      v-model="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <div class="mb-3">
      <label class="form-label">Email</label>
      <input
        type="email"
        v-model="value.email"
        class="form-control form-control-lg"
        id="LoginInput"
      />
      <span id="error" v-if="v$.email.$error">
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>

    <div class="mb-4">
      <label class="form-label">Password</label>
      <input
        type="password"
        v-model="value.password"
        class="form-control form-control-lg"
        id="LoginInput2"
      />
      <span id="error" v-if="v$.password.$error">
        {{ v$.password.$errors[0].$message }}
      </span>
    </div>
    <Button @click.prevent="loginApp" buttonText="Login" />
  </form>
</template>

<script>
import Button from "../../ReusableUI/button.vue";
import { required, email, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import router from "@/router";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "Input",

  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  setup() {
    const value = reactive({
      email: "",
      password: "",
      error: false,
    });

    const rules = computed(() => {
      return {
        email: { required, email },
        password: { required, minLength: minLength(6) },
      };
    });
    const v$ = useVuelidate(rules, value);

    return { value, v$ };
  },

  components: {
    Button,
    Loading,
  },

  validations() {
    return {
      email: { required },
      password: { required },
    };
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    loginApp() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const details = this.value;
        this.isLoading = true;
        this.login(details)
          .then((success) => {
            if (
              success.code == 200 &&
              success.user.email_verified_at !== null
            ) {
              Swal.fire("Login Successful", success.message, "success");
              this.isLoading = false;
              router.push({ name: "Home" });
            } else if (
              success.code == 200 &&
              success.user.email_verified_at == null
            ) {
              this.isLoading = false;
              router.push({ name: "verify" });
            } else {
              Swal.fire({
                icon: "error",
                title: success.message,
                text: "Invalid Email or Password",
              });
              this.isLoading = false;
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error,
            });
            this.isLoading = false;
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
#LoginInput {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#LoginInput2 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

span {
  color: red;
}
</style>
