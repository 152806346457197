import { createWebHistory, createRouter } from "vue-router";
import AuthLayout from "@/layouts/AuthLayout";
import Login from "../components/Auth/Login/Login.vue";
import SignUp from "../components/Auth/SignUp/SignUp.vue";
import Verify from "../components/Auth/SignUp/VerifyAccount.vue";
import Layout from "../components/DashboardLayout/Layout.vue";
import Dashboard from "../pages/Dashboard.vue";
import Profile from "../pages/MyProfile.vue";
// import store from "../../src/store/Module/auth";
import Payments from "../pages/Payments.vue";
import Track from "../pages/TrackOrder.vue";
import Restaurant from "../pages/Restaurants.vue";
import Vendor from "../pages/Vendor.vue";
import Reviews from "../pages/Reviews.vue";
import SingleVendor from "../components/Home/SingleVendorPage/SingleVendor.vue";
import CartCheckOut from "../components/Home/SingleVendorPage/CartCheckOutPage.vue";
import FoodCategory from "../pages/DashboardInnerComponents/FoodCategory";
import VendorRoutes from "../views/VendorDashboard/router";
import Rider from "../views/RiderDashboard/router";
import CreateNew from "../pages/DashboardInnerComponents/CreateNew";
import RiderInfo from "../pages/DashboardInnerComponents/riderinfo.vue";
import DocumentUpload from "../pages/DashboardInnerComponents/riderdocumentupload.vue";
import Becomerider from "../pages/becomerider.vue";

const routes = [
    ...VendorRoutes,
    ...Rider,
    {
        path: "/",
        name: "Home",
        meta: { layout: AuthLayout },
        component: () =>
            import ("@/views/Home.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/signUp",
        name: "signUp",
        component: SignUp,
    },

    {
        path: "/verify",
        name: "verify",
        component: Verify,
    },
    {
        path: "/",
        redirectTo: "Dashboard",
        name: "Layout",
        component: Layout,
        beforeEnter: (to, from, next) => {
            const data = localStorage.getItem("role");
            if (data == "user") {
                next();
            } else {
                next("/login");
            }
        },
        children: [{
                path: "Dashboard",
                name: "Dashboard",
                component: Dashboard,
            },
            {
                path: "profile",
                name: "profile",
                component: Profile,
            },
            {
                path: "/payment",
                name: "payment",
                component: Payments,
            },
            {
                path: "track",
                name: "trackProduct",
                component: Track,
            },
            {
                path: "rider-reg",
                name: "rider-reg",
                component: Becomerider,
            },
            {
                path: "restaurant",
                name: "restaurant",
                component: Restaurant,
            },
            {
                path: "vendor-page",
                name: "vendor-page",
                component: Vendor,
                props: true,
            },
            {
                path: "review",
                name: "review",
                component: Reviews,
            },
            {
                path: "food-category",
                name: "food-category",
                component: FoodCategory,
                props: true,
            },
            {
                path: "rider-info",
                name: "rider-info",
                component: RiderInfo,
            },

            {
                path: "upload",
                name: "upload",
                component: DocumentUpload,
            },
            {
                path: "create-new",
                name: "create-new",
                component: CreateNew,
            },
        ],
    },
    {
        path: "/single-vendor",
        name: "singleVendor",
        component: SingleVendor,
    },
    {
        path: "/checkout",
        name: "checkout",
        component: CartCheckOut,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "login",
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;