<template>
  <div>
    <Header />
    <div class="container my-5" id="nav">
      <div class="card-img-top" :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${image})`"></div>
      <div class="order-details">
        <div class="row">
          <div class="col-12">
            <div class="name fs-3">
              <!--img :src="restaurant" /-->
              <span class="fw-bolder px-3">{{ vendorName }}</span>
            </div>
          </div>
          <div class="col-12 row mt-2 text-muted">
            <div class="col-12 col-md-4">
              <div class="address">
                <img :src="location" alt="" />
                {{ vendorLocation }}
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="min">
                
                <span class="px-2">{{ lat }} Min</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div>
                <img :src="bike" alt="" />
                <span class="px-2">{{ long }} Miles away</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-md-8">
          <div class="controller-header">
            <div class="menu">Menu</div>
            <div><img :src="size" alt="" /></div>
          </div>
          <div class="filter-btns">
            <div v-for="(item, index) in menu" :key="index">
              <button id="btn" class="btn btn">{{ item }}</button>
            </div>
          </div>
          <Product :availableProducts="availableFoodMenu" />
        </div>
        <div class="col-md-4">
          <div class="cart-container my-4">
            <template v-if="getAllCartItems.length > 0">
              <h5 class="fw-bolder">{{ vendorName }}</h5>
              <div>
                <div class="delivery-time">ESTIMATED DELIVERY TIME</div>
                <small class=" text-muted">28-48 minutes</small>
              </div>

              <div class="summary-background py-1 pb-2" v-for="(item, index) in getAllCartItems" :key="index">
                <div class="cart-summmary">
                  <div>{{ item.product.name }}</div>
                  <div>&#8358;{{ item.product.price }}</div>
                </div>

                <div class="cart-summmary">
                  <div class="deleteItem" @click="removeProduct(item.product)">
                    remove<span><img :src="remove" alt="" /></span>
                  </div>
                  <div class="qty">
                    <div @click="decreaseQty(item.product)" class="controller">
                      -
                    </div>
                    <div class="no-qty">{{ item.quantity }}</div>
                    <div @click="increaseQty(item.product)" class="controller">
                      +
                    </div>
                  </div>
                </div>
              </div>

              <div class="amount-container">
                <div class="sub-total">
                  <div>Subtotal</div>
                  <div>&#8358;{{ totalprice }}</div>
                </div>

                <div class="total">
                  <div>Total Amount</div>
                  <div>&#8358;{{ totalprice }}</div>
                </div>
              </div>
              <Button
                id="button"
                @click="checkOut"
                buttonText="PROCEED TO CHECKOUT"
              />
            </template>
            <template v-else>
              <div class="py-5 my-5 text-center text-muted">
                <div class="fs-6 fw-bold">Cart is empty</div>
                <small>select any food item to continue</small>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../NavBar.vue";
import Product from "../SingleVendorPage/ProductDisplay.vue";
import Button from "../../ReusableUI/button.vue";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      header: require("../../../assets/singleVendor.png"),
      restaurant: require("../../../assets/restaurants.png"),
      location: require("../../../assets/location.png"),
      bike: require("../../../assets/bike.png"),
      size: require("../../../assets/size.png"),
      remove: require("../../../assets/remove.png"),
      vendorDetails: [],
      vendorName: "",
      vendorLocation: "",
      menu: [],
      availableFoodMenu: [],
      image: "",
      lat: "",
      long: "",
      vendor_list: [],
    };
  },

  components: {
    Header,
    Product,
    Button,
  },

  methods: {
    ...mapGetters({
      cartItems: "general/itemsInCart",
      total: "general/cartTotalPrice",
    }),

    ...mapActions({
      removeItem: "general/removeProduct",
      increaseCartQuantity: "general/increaseQty",
      decreaseCartQuantity: "general/decreaseQty",
    }),

    removeProduct(product) {
      const productId = product.id;
      this.removeItem({
        productId,
      });
    },

    increaseQty(product) {
      const productId = product.id;
      this.increaseCartQuantity({
        productId,
      });
    },

    decreaseQty(product) {
      const productId = product.id;
      this.decreaseCartQuantity({
        productId,
      });
    },

    checkOut() {
      router.push({ name: "checkout" });
    },
  },

  computed: {
    getAllCartItems() {
      return this.cartItems();
    },

    totalprice() {
      return this.total();
    },
  },

  async created() {
    this.vendorDetails = this.$store.state["vendor"].singleVendorDetails;
    // console.log(this.vendorDetails);
    for (var i = 0; i < this.vendorDetails.length; i++) {
      this.vendorName = this.vendorDetails[i].business_name;
      this.vendorLocation = this.vendorDetails[i].business_address;
      // this.menu = this.vendorDetails[i].vendor_list.category;
      this.image = this.vendorDetails[i].image;
      this.lat = this.vendorDetails[i].latitude;
      this.long = this.vendorDetails[i].longitude;
      this.vendor_list = this.vendorDetails[i].vendor_list;
      for (var j = 0; j < this.vendor_list.length; j++) {
        this.menu.push(this.vendor_list[j].category.name);
        // console.log(this.vendor_list[j])
        // this.vendor_list[j].menu = this.vendor_list[j].menu.map((m) =>{
        //   return{
        //     ...m,
        //     categoryName: this.vendor_list[j].category.name
        //   }
        // })
        this.availableFoodMenu = [
          ...this.availableFoodMenu,
          ...this.vendor_list[j].menu,
        ];
      }
    }
  },
};
</script>

<style scoped>
.card-img-top {
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  min-height: 500px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#button {
  margin-top: 10%;
}

#nav {
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.amount-container {
  margin-top: 20%;
  color: #04030f;
  font-weight: 500;
}
.sub-total {
  display: flex;
  justify-content: space-between;
  color: #04030f;
  font-weight: 500;
}

.total {
  display: flex;
  justify-content: space-between;
}

.order-content {
  display: flex;
  justify-content: left;
}
.order-details {
  display: block;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  padding: 2%;
}

.deleteItem {
  color: red;
  cursor: pointer;
}

.food-name {
  padding: 1% 3%;
}

.controller-header {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.menu {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #04030f;
}

.filter-btns {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  padding-bottom: 4%;
  padding-bottom: 3%;
}
#btn {
  padding: 3px 20px;
  border-radius: 70px;
  border: 0.5px solid #e0e0e0;
}

#btn:hover {
  background-color: #854d27;
  color: #fff;
}

.cart-container {
  background: #ffffff;
  box-shadow: 0px 2px 50px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
}

.delivery-time {
  color: #43424c;
  padding-top: 20px;
  font-size: 12px;
}

.my-time {
  color: #04030f;
  font-weight: 600;
  font-size: 16px;
}

.cart-summmary {
  display: flex;
  justify-content: space-between;
  padding: 2% 5%;
}

.summary-background {
  margin-top: 20px;
  background: #fdf2eb;
  box-shadow: 0px 1.5px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.qty {
  display: flex;
  font-size: 13px;
}

.controller {
  background: #ffffff;
  margin: 0px 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3px 10px;
  cursor: pointer;
}

.cart-summmary span img {
  padding-left: 10px;
}

.no-qty {
  padding-top: 4px;
}
</style>
