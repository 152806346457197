<template>
  <div class="das-container">
    <h4>Become a Vendor</h4>
    <p>In this section, enter your menu or list of products</p>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-2">
            <img :src="product" />
          </div>
          <div class="col-md-10">
            <div class="order-details">
              <div>
                <div class="res-name">Tasty Food Restaurants</div>
              </div>
            </div>
            <div class="food-details">
              African Dishes, Sushi, Continental, Nigerian, Cousines, Pasteries,
              Fruites, drinks, Snacks, American, Outdoor and indoor cooking
            </div>
            <div class="food-details">
              <img :src="location" alt="" />
              <span class="address"
                >No. 35 Ajose Adeogun Street Utako, Abuja</span
              >
            </div>
            <div class="star-rating">
              <star-rating v-model:rating="rating" v-bind:star-size="20" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="next">
    <Button id="btn" @click="next" btnText="Create New" />
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import Button from "../../components/ReusableUI/SmalllButton.vue";

export default {
  name: "CreateNew",
  data() {
    return {
      location: require("../../assets/location.png"),
      product: require("../../assets/soup.png"),
    };
  },

  components: {
    StarRating,
    Button,
  },
};
</script>

<style scope>
.address {
  padding-left: 2%;
}
.das-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.das-container p {
  padding-top: 2%;
}

.details {
  display: flex;
}

.img img {
  width: 80%;
}

.food-details img {
  width: 2%;
}

.next {
  text-align: right;
  padding-top: 3%;
}
</style>
