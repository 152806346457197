<template>
  <nav id="background" class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid" id="whole-container">
      <a id="logo" class="navbar-brand fs-4 fw-bolder" href="/"><img :src="MyLogo" height="30" /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul id="menus" class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">About</a>
          </li>

          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Menu</a>
          </li>

          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"
              >Restaurants</a
            >
          </li> -->
        </ul>
        <div id="dropdown" class="btn-group">
          <div
            class="px-4 pb-3 pb-md-0 fs-6 dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ username }}
          </div>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><a class="dropdown-item" href="#">Recent Order</a></li>
            <li>
              <a @click="logOut()" class="dropdown-item">Logout</a>
            </li>
          </ul>
          
        </div>
        <div id="cart-icon">
            <i class="fas fa-shopping-cart"></i> 
            <span class="mx-2 cart-count">{{ cartItemCount }}</span>
          </div>
        
      </div>
    </div>
  </nav>
</template>

<script>
import router from "@/router";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      MyLogo: require("../../assets/foodplace.png"),
      username: "",
    };
  },

  methods: {
    ...mapActions({
      getOut: "auth/signOut",
    }),

    ...mapGetters({
      totalItem: "general/cartItemsCount",
    }),

    logOut() {
      this.getOut();
      router.push({ name: "Home" });
      this.$store.state.auth.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("pass");
      localStorage.removeItem("role");
      localStorage.removeItem("vendor_list_id");
      localStorage.removeItem("id");
    },
  },

  async created() {
    this.username = this.$store.state["auth"].user?.first_name;
  },

  computed: {
    cartItemCount() {
      return this.totalItem();
    },
  },
};
</script>

<style scoped>
#background {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
}

.dropdown {
  margin-right: 10%;
}

#logo {
  color: #854d27;
  font-weight: 600;
}

#btn {
  color: #854d27;
  padding: 3px 20px;
  border: 1px solid #854d27;
  outline-color: #854d27 !important;
  margin-right: 20%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

#cart-icon i {
  padding-top: 13px;
  color: #854d27;
}

#menus {
  padding-left: 9%;
}

#menus li {
  padding-right: 29%;
}

#menu a {
  color: #43424c;
}

#whole-container {
  margin: 0px 6.3%;
}

.dropdown-menu a {
  cursor: pointer;
}

.cart-count {
  font-size: 15px;
  color: #854d27;
  padding: 0 5px;
  padding-top: 0px !important;
  margin-left: -1px;
  background: #f8e9f2;
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

@media only screen and (max-width: 600px) {
  #whole-container {
    margin: 0px 20px;
  }
  #menus {
    padding-left: 0%;
  }

  #btn {
    margin-right: 10%;
  }
}
</style>
