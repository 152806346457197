import { createStore } from "vuex";
import auth from "./Module/auth";
import vendor from "./Module/vendor";
import general from "./Module/general";
import order from "./Module/order";
import rider from "./Module/rider";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    vendor,
    general,
    order,
    rider,
  },
  plugins: [
    createPersistedState({
      paths: ["general", "auth", "vendor", "order", "rider"],
    }),
  ],
});
