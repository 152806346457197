<template>
  <div class="btn">
    <button id="my-btn" v-on="listeners" class="btn btn" type="submit">
      {{ btnText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SmallButton",
  props: {
    btnText: {
      type: String,
      default: () => "Action",
    },
  },
};
</script>

<style scoped>
.btn {
  color: #fff;
  border: none;
  outline: none;
}

/* #my-btn{
    background-color: rgba(133, 77, 39, 1);
} */

.btn {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}
</style>
