<template>
  <div class="background">
    <div class="logo"><img class="img-fluid" :src="MyLogo" alt="logo" /></div>

    <div class="chef">
      <img class="img-fluid" :src="Chef" alt="chef" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Flyer",
  data() {
    return {
      MyLogo: require("../../../assets/foodplace_white.png"),
      Chef: require("../../../assets/chef.png"),
    };
  },
};
</script>

<style scoped>
.background {
  background-color: rgba(133, 77, 39, 1);
  height: 100vh;
  width: 100%;
  text-align: center;
}

.logo img {
  width: 30%;
  height: auto;
}

.logo {
  padding-top: 15%;
}

.chef {
  text-align: center;
  align-content: center;
}

.chef img {
  padding-top: 7%;
  height: auto;
  margin-left: 10%;
  text-align: center;
  align-content: center;
}
</style>
