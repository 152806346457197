<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
  <div class="category-container">
    <h5>Submit Documents</h5>
    <div class="row">
      <div>
        <label>Passport Photograph</label>
        <div class="input-group input-group mb-3">
          <input
            type="file"
            class="form-control"
            @change="getPassport"
            multiple
          />
        </div>

        <label>National ID Card</label>
        <div class="input-group input-group mb-3">
          <input
            type="file"
            @change="getNationalId"
            class="form-control"
            multiple
          />
        </div>

        <label>Drivers Licence</label>
        <div class="input-group input-group mb-3">
          <input
            type="file"
            class="form-control"
            @change="getDiversLicence"
            multiple
          />
        </div>

        <label>Proof of Address</label>
        <div class="input-group input-group mb-3">
          <input
            type="file"
            @change="getAdressProof"
            class="form-control"
            multiple
          />
        </div>
      </div>

      <!-- buttons -->
      <div class="controllers">
        <div><Button id="btn" @click="Submit" btnText="SUBMIT" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/ReusableUI/SmalllButton.vue";
import { mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import router from "@/router";

export default {
  name: "FoodCategory",
  props: ["info"],

  data() {
    return {
      isLoading: false,
      fullPage: true,
      passport: "",
      national_id: "",
      driver_license: "",
      address_prove: "",
      regInfo: [],
      user_id: "",
      knowAreasArray: [],
      properties: [],
    };
  },

  components: {
    Button,
    Loading,
  },

  methods: {
    ...mapActions({
      registerRider: "rider/createRider",
    }),

    // method to get file value
    getPassport(event) {
      this.passport = event.target.files;
    },

    getNationalId(event) {
      this.national_id = event.target.files;
    },

    getDiversLicence(event) {
      this.driver_license = event.target.files;
    },

    getAdressProof(event) {
      this.address_prove = event.target.files;
    },

    Submit(event) {
      event.preventDefault();
      this.isLoading = true;
      const {
        business_address,
        city_id,
        email,
        first_name,
        image,
        last_name,
        phone,
        start_date,
        state_id,
      } = this.regInfo;
      var formData = new FormData();
      formData.append("business_address", business_address);
      formData.append("city_id", city_id);
      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("image", image);
      this.knowAreasArray.forEach((item) => {
        for (const property in item) {
          formData.append(`known_areas[]`, `${item[property]}`);
        }
      });
      formData.append("last_name", last_name);
      formData.append("phone", phone);
      formData.append("start_date", start_date);
      formData.append("state_id", state_id);
      formData.append("passport", this.passport[0]);
      formData.append("national_id", this.national_id[0]);
      formData.append("driver_license", this.driver_license[0]);
      formData.append("user_id", this.user_id);
      formData.append("address_prove", this.address_prove[0]);
      this.properties.forEach((item) => {
        formData.append(`properties[]`, item);
      });
      this.registerRider(formData).then((response) => {
        if (response == undefined) {
          this.isLoading = false;
          router.push({ name: "Dashboard" });
        } else {
          this.isLoading = false;
        }
      });
    },
  },

  async created() {
    this.regInfo = this.$store.state["rider"].riderInfo;
    this.knowAreasArray = this.regInfo.known_areas;
    this.properties = this.regInfo.properties;
    this.user_id = this.$store.state["auth"].user.id;
  },
};
</script>

<style scoped>
.category-container {
  padding: 0% 5%;
}
.category-container h5 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.city-area {
  margin-top: 3%;
}

#food {
  display: flex;
  margin: 10px;
}

ul {
  padding-left: 0%;
  /* margin-top: 30px; */
}

.category-container p {
  padding-top: 2%;
}

span {
  color: #04030f;
}

.controllers {
  padding-top: 10%;
  text-align: right;
}

.prev-button button {
  background-color: #fff !important;
  background: #ffffff;
  border: 0.5px solid #43424c;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  color: rgba(133, 77, 39, 1);
  padding: 8px 15px;
}

#btn {
  background-color: rgba(133, 77, 39, 1);
  color: #fff;
}
</style>
