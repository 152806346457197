<template>
  <Vendor />
</template>

<script>
import Vendor from "./DashboardInnerComponents/VendorInput.vue";
export default {
  components: {
    Vendor,
  },
};
</script>

<style scoped></style>
